import React from 'react'
import { useTranslation } from 'react-i18next'
import CTAButtonRow from '../components/ctaButtonRow/CTAButtonRow'

import Layout from '../components/layout'
import SEO from '../components/seo'

function Success() {
    const { t } = useTranslation('thankYou')

    return (
        <Layout>
            <SEO title="thankYou:sent" description="thankYou:contactingUs" />
            <div className="thankYouHeader">
                <h1 className="pageTitle">{t('sent')}</h1>
                <span className="thankYouSubtitle">{t('contactingUs')}</span>
                <CTAButtonRow />
            </div>
        </Layout>
    )
}

export default Success
